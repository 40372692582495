import './styles.css'
import {MountPoint} from "./mount-point";
import {IFRAME_EVENT} from "./const";

export type EmbedLiveChatParams = {
  tenant_id: string
  bot_code: string
}

export async function init({tenant_id, bot_code}: EmbedLiveChatParams) {
  let bubbleIframe: HTMLIFrameElement,
      dialogIframe: HTMLIFrameElement,
      isOpenDialogChat = false;

  function updateFullWidthBubble() {
    //Sau 5s tự mở rộng để hiển thị greeting
    setTimeout(() => {
      if (dialogIframe.className !== 'chat-dialog-in' && !isOpenDialogChat) {
        // @ts-ignore
        if (!document.getElementById("tovaBubble")?.classList.contains("tova-bubble-greeting")) {
          document.getElementById("tovaBubble")?.classList.add("tova-bubble-greeting")
        }
      }
    }, 5000)
  }

  function createMountPoint(): MountPoint {
    // Root chứa các iframe
    const root = document.createElement("div");
    root.id = 'tova-root';
    root.className = 'tova-reset-css';
    document.body.insertBefore(root, document.body.firstChild);

    // Div cho khung chat
    const dialogDiv = document.createElement('div');
    dialogDiv.className = 'tova-dialog';
    dialogDiv.id = 'tovaDialog';

    // Div cho bong bóng chat
    const bubbleDiv = document.createElement('div');
    bubbleDiv.className = 'tova-bubble';
    bubbleDiv.id = 'tovaBubble';
    root.appendChild(dialogDiv);
    root.appendChild(bubbleDiv);
    return new MountPoint(bubbleDiv, dialogDiv)
  }

  async function mountIframe(mountPoint: MountPoint): Promise<void> {
    // Mount Iframe bubble
    bubbleIframe = document.createElement('iframe');
    bubbleIframe.addEventListener('load', () => {
      // Message channel to sent event between our window and iframe
      const bubbleChannel = new MessageChannel();
      const bubbleOurPort = bubbleChannel.port1;
      const bubbleTheirPort = bubbleChannel.port2;
      bubbleOurPort.onmessage = bubbleOurPortOnMessage;
      // Sync onMounted vuejs vs iframe load
      setTimeout(() => {
        bubbleIframe.contentWindow!.postMessage('init', '*', [bubbleTheirPort])
        // Animation grow cho chat bubble
        bubbleIframe.style.display = 'block';
        bubbleIframe.className = 'chat-bubble-grow'
      }, 1000)
    })
    bubbleIframe.src = `${getOrigin()}/live-chat/bubble?tenant_id=${tenant_id}&bot_code=${bot_code}`
    mountPoint.bubble.appendChild(bubbleIframe)

    // Mount Iframe dialog
    dialogIframe = document.createElement('iframe');
    dialogIframe.addEventListener('load', () => {
      // Message channel to sent event between our window and iframe
      const dialogChannel = new MessageChannel();
      const dialogOurPort = dialogChannel.port1;
      const dialogTheirPort = dialogChannel.port2;
      // Sync onMounted vuejs vs iframe load
      setTimeout(() => {
        dialogIframe.contentWindow!.postMessage('init', '*', [dialogTheirPort])
      }, 1000)
      dialogOurPort.onmessage = dialogOurPortOnMessage;
      updateFullWidthBubble()
      //Sau 5s tự mở cửa sổ chat
      // setTimeout(() => {
      //     if (dialogIframe.className !== 'chat-dialog-in' && !isOpenDialogChat) {
      //         dialogIframe.style.display = 'block'
      //         dialogIframe.className = 'chat-dialog-in'
      //     }
      // }, 5000)
    })
    dialogIframe.src = `${getOrigin()}/live-chat?tenant_id=${tenant_id}&bot_code=${bot_code}`
    mountPoint.dialog.appendChild(dialogIframe)
  }

  await mountIframe(createMountPoint())

  function bubbleOurPortOnMessage(e: any) {
    if (e.data === IFRAME_EVENT.BUBBLE_CLICKED) {
      // @ts-ignore
      document.getElementById("tovaBubble")?.classList.remove("tova-bubble-greeting")
      dialogIframe.style.display = 'block'
      if (dialogIframe.className === 'chat-dialog-in' || dialogIframe.className === 'chat-dialog-grow-height') {
        isOpenDialogChat = false;
        dialogIframe.className = 'chat-dialog-out'
        updateFullWidthBubble()
      } else {
        isOpenDialogChat = true;
        dialogIframe.className = 'chat-dialog-in'
      }
    }
  }

  function dialogOurPortOnMessage(e: any) {
    switch (e.data) {
      case IFRAME_EVENT.MINIMIZED:
        dialogIframe.className = 'chat-dialog-out'
        isOpenDialogChat = false;
        updateFullWidthBubble()
        break;
      case IFRAME_EVENT.VISITOR_IDENTIFY:
      case IFRAME_EVENT.CHAT_STARTED:
        // Mở rộng khung chat với điều kiện desktop hoặc mobile
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw > 768) {
          dialogIframe.style.height = '710px'
        } else {
          dialogIframe.style.height = '100%'
        }
        dialogIframe.style.display = 'block'
        // dialogIframe.className = 'chat-dialog-grow-height'
        isOpenDialogChat = true;
        break;
      case IFRAME_EVENT.VISITOR_END_CHAT:
        dialogIframe.style.height = '255px'
        dialogIframe.className = 'chat-dialog-out'
        isOpenDialogChat = false;
        break;
      default:
        break;
    }
  }

  function getOrigin() {
    // return "http://localhost:5173"
    try {
      const cdnUrl = document.getElementById("tova-jssdk") as HTMLScriptElement
      const parsedUrl = new URL(cdnUrl.src);
      return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
    } catch (error) {
      console.warn('Invalid URL:', error);
      return "https://tova-dev.fpt.ai";
    }
  }
}

// @ts-ignore
window.onload = tovaAsyncInit
